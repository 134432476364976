.coursesContainer {
    .addNewCourse {
        margin-top: 20px;
        padding-left: 10px; padding-right: 10px;
    }

    .newCourseContainer {
        border: 1px solid #000; border-radius: 3px;
        margin-top: 10px; margin-bottom: 10px;
        // background-color: #eee;
    }

    .allCoursesContainer {
        border: 2px solid #000; border-radius: 3px;
        padding: 5px;
        height: 200px;
        overflow: auto;

        table.coursesList {
            border: 1px solid #ccc;
            width: 100%;

            thead th {
                background-color: #333; color: #aaa;
                height: 30px;
            }
            tbody tr {
                border: 1px solid #f00;
                height: 30px;
            }
            tbody td {
                text-align: center;
            }
        }

        input[type="button"] {
            padding-left: 10px; padding-right: 10px;
        }
    }

}

