@import url('https://fonts.googleapis.com/css?family=Inter');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cookieConsent{
  font-size: 18px !important;
}
.whatsupImg{
  width: 70px;
  position: fixed;
  bottom: 20px;
  zIndex: 9999;

  right: 70px;
}
.containerStyle{
  background: inherit;
  background-size: cover;
  width: 60px;
  position: fixed;
  bottom: 10px;
  z-index: 9999;
  animation: vibrate 1s ease infinite; 

  right: 70px;
}
@keyframes vibrate {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(2px, 0);
  }
  20% {
    transform: translate(-2px, 0);
  }
  30% {
    transform: translate(2px, 0);
  }
  40% {
    transform: translate(-2px, 0);
  }
  50% {
    transform: translate(2px, 0);
  }
  51%{
    transform: translate(0, 0);

  }
  100%{
    transform: translate(0, 0);

  }

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
p{
font-family: Inter !important;
}
div{
  font-family: Inter !important;
}
span{
  font-family: Inter !important;
}
strong{
  font-family: Inter;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
