.section2_container{
display: flex;
justify-content: center;
}
.section2_inner_container{
    margin-top: 50px;
    max-width: 1100px;
    width: 100%;
}
.section2_heading{
    color: black;
     font-size: 36px;
     font-family: Whyte Inktrap;
     font-weight: 700;
     line-height: 37.01px;
     word-wrap: break-word
}
.section2_text{
    color: rgba(0, 0, 0, 0.60);
     font-size: 16px;
     font-family: Inter;
     font-weight: 400;
     line-height: 25.16px;
     word-wrap: break-word
}
.Nav_name{
    color: rgba(0, 0, 0, 0.60);
     font-size: 18px;
     font-family: Inter;
     font-weight: 600;
     line-height: 25.16px;
     width: 150px;
     word-wrap: break-word;
     
     cursor: pointer;

     
}
.Nav_name:hover{
    color:#0D47A1;
    font-size:20px;
}
.course_container{
    max-width: 1100px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgb(197, 195, 195);
    padding: 25px;
    
}
.Course_cart_head{
color: black;
 font-size: 20px;
 font-family: Whyte Inktrap;
 font-weight: 700;
 line-height: 19.89px;
 word-wrap: break-word;
}
.Course_cart_text{
    color: rgba(0, 0, 0, 0.60);
font-size: 14px;
font-family: Inter;
font-weight: 400;
line-height: 19.57px;
word-wrap: break-word
}

/* cauresal css */
.home_testimonials_main_container{
    display: flex;
   margin-top: 100px;
    justify-content: center;
   
}
.home_testimonials_container{
    align-items: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:920px ;
    margin-bottom: 50px;
    background-color: #F3F5F6;
    height: 550px;
    
    border-radius: 40px;
}
.btn_testimonial_l{
    position: absolute;
    top: 50%;
    left: -40px;
    background-color: white;
    border: none;
}
.btn_testimonial_r{
    position: absolute;
    top: 50%;
   right: -40px;
   background-color: white;
    border: none;

}
.slick-prev:before, .slick-next:before{
    color:transparent !important;
}
.testimonial_home_card{
    width: 280px;
    border-radius: 20px;
    margin: 20px 10px;
    /* box-shadow: 0px 20px 50px 0px #74738414; */
    height: 410px;
    position: relative;
    background-color: #ffff;
}
.testimonial_home_head{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 30px;
margin-left:20px ;
margin-bottom: 10px;
letter-spacing: 1px;
text-transform: capitalize;
color: #000000;

}
.testimonial_home_head_text{
    margin-bottom: 20px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 25px;
margin-left:20px ;

letter-spacing: 1px;
text-transform: capitalize;

color: rgba(0, 0, 0, 0.6);

}
.test_coresal_home{
    max-width:1000px;
    margin-top: 20px;
   
}

@media screen and (max-width:950px){
    .test_coresal_home{
        max-width:600px;
       
    }
}
@media screen and (max-width:650px){
    .test_coresal_home{
        max-width:330px;
      
      

    }
}
.testimonial_home_text2{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
margin-bottom: 20px;

font-size: 26px;
margin-left:20px ;
letter-spacing: 1px;
text-transform: capitalize;

color: #000000;

}
* {
    margin: 0;
   /* padding: 0; */
  }
  .testimonials_readmore{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
padding-left: 10px;
color: rgba(28, 28, 28, 0.6);
margin-top: 10px;
text-decoration: underline;

  }
  .testimonials_review{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
padding-left: 10px;
color: #1C1C1C;
margin-top: 10px;


  }
  
.testimonial_home_card_text{
    
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
padding: 10px;
/* or 30px */

color: #1C1C1C;
}
.testimonial_home_card_container{
    display: flex;
    position: relative;
}
/* 
.testimonial_home_logo{
   
    margin: 20px 20px;
    background: url(../../Images/Images/Customers.png) 0/contain repeat-x;
    margin-top: 35px;    
    display: flex;
    width: 15000px;
    height: 150px;
    -webkit-animation:moving-logos 250s linear infinite;
    animation: moving-logos 250s linear infinite;
}

@keyframes moving-logos{
    0%{
        -webkit-transform:translateZ(0);
         transform: translateZ(0);
    }
    100%{
        -webkit-transform:translate3d(-15000px,0,0);
        transform: translate3d(-15000px,0,0);

    }
} */

.testimonials2_main_container{
    display: flex;
     justify-content: center;
}
.testimonials2_center_container{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width:1250px ;
    
}
.testimonials2_customer_text{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
   
    text-transform: capitalize;
    
    color: #000000;
    
}
.testimonials2_customer_container{
    width: 300px;
    margin:30px 50px ;
}
.testimonials2_customer_container_text{
    
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 50px;
letter-spacing: 1px;
text-transform: capitalize;

color: #000000;

}

.testimonials2_customer_readmore_btn{
    background: #0D47A1;
    box-shadow: -11.09px 18.13px 36.6483px rgba(13, 71, 161, 0.13);
    border-radius: 50px;
    border: 1px solid white;
    color: white;
    height: 40px;
    width: 100px;
    margin-top: 20px;
    margin-left: 20px;
}



.product-carousel {
    position: relative;
    overflow: hidden;
    padding: 26px;
}

.product-container {
    padding: 0 10px;
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.right-arrow {
    position: absolute;
    top: 45%;
    right: -30px;
    font-size: 3rem;
   
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    /* position: ; */
    position: absolute;
    top:45%;
    left: -30px;
    font-size: 3rem;
    
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }

  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1.5s;
    transform: scale(1.08);
  }
  
  

.test_cart_container{
    display: flex;
    
}
.Testimonials_heading{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
text-align: center;
letter-spacing: 1px;
text-transform: capitalize;

color: #000000;
}