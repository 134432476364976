.dropdown-menu {
  background: red;
  width: 200px;
  position: absolute;
  top: 120px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background:white;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: rgba(37,214,225,1);
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: black;
  font-size: 15px;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
