.newCourseContent {
    padding: 20px;

    .courseInputContainer {
        margin-bottom: 20px;

        input, textarea {
            display: block;
            width: 400px; height: 25px;
            padding-left: 15px;
        }
    }

    #newCourseForm {
        .certifications, .courseContents, .resources, .reviews {
            display: flex; gap: 15px;
            margin-bottom: 5px;

            .btn-add {
                margin-top: 5px;
            }
        }


        .newCourseSubmit {
            padding-left: 15px; padding-right: 15px;
        }
    }
}

