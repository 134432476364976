.Consultant_head_container{
position: relative;
width: 100%;
height: auto;
}
.Consultant_section1{
    width: 100%;
    background-color: rgb(224, 223, 223);
    display: flex;
    justify-content: center;
}
.consultant_inner1{
    width: 90%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 30px;
}
.consultant_inner_r{

width: 45%;
}
.consultant_inner_l{
    display: flex;
    align-items: center;
width: 45%;
}

.consultant_subhead{
font-family: Inter;
font-size: 21px;

}
.consultant_text{
    font-family: Inter;
    font-size: 16px;
}
.Consutant_head{
    font-size: 30px;
    font-family:Inter;
}
.Consultant_section2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.Consultant_Inner2{
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    gap:30px
}
.consutant_Container{
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 10px;
    border-radius: 10px;

}
.consultant_Section3{
    position: relative;
    width: 100%;
    height: 80vh;
    margin-top: 70px;

}
.Consultant_form_container{
    position: absolute;
    top: 20%;
    left: 10%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
   
    max-width: 500px;
    

}
.small_input{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}
.consultant_input{
    width: 220px;
    border-radius: 5px;
    border:none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    font-family: Inter;
    font-size: 16px;
    height: 40px;
}
.consultant_book_btn{
    width: 150px;
    height: 50px;
    border-radius: 10px;
    background-color: #1A53DD;
    border: 1px solid #1A53DD;
    color: white;
    font-size: 18px;
    font-family: Inter;
    text-align: center;
    margin-top: 50px;

}

@media screen and (max-width:600px){
    .consultant_inner_r{
        display: none !important;
    }
    .consultant_inner_l{
     width: 90%;
    }
    .small_img{
        display:none;
    }
    .Consultant_form_container {
        position: absolute;
        top: 20%;
        left: 5%;
        padding: 15px;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        max-width: 350px;
    }
    .consultant_input {
        width: 300px !important;
        border-radius: 5px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        font-family: Inter;
        font-size: 16px;
        height: 40px;
    }
    .remove_gap{
        gap: 0px !important;
    }
    .Consutant_head{
    font-size: 30px !important;
    }
}