.navbar {
  background: white;
  height: 80px;
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.Navsocial {
  display: grid;

  height: 35px;
  grid-template-columns: 29rem 4fr 1fr;
}
.Nav_lcomp {
  margin-top: 5px;
  grid-column: 2/3;
}
.Nav_rcomp {
  grid-column: 3/4;
}
.navbar-logo {
  color: #fff;
  justify-self: start;

  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: black;
  font-size: 17px;
  opacity: 0.9;
  font-family: Inter;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  color: rgba(37, 214, 225, 1);

  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .Navsocial {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;

    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    color: white;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 10%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: rgba(37, 214, 225, 1);
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  .btnn {
    display: none !important;
  }
}
