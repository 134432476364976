.left {
  margin-left: 40px;
  grid-column: 2/3;
  margin-top: 140px;
}
.heading {
  font-size: 60px;
  margin: 5px 0px;
  opacity: 0.8;
}
.heading2 {
  font-size: 60px;
  margin: 0px 0px;
  color: rgba(37, 214, 225, 1);
}
.Services {
  display: flex;
  justify-content: center;
}
.servicecont {
  margin: 20px 20px;
}
.b1image {
  margin: 25px 25px;
  width: 100px;
  height: 100px;
}
.Servicebox {
  width: 1100px;
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin: 30px 20px;

  /* width: 100%;
    display: grid;
    
    grid-template-columns:50rem 1fr 1fr 55rem; */
}

.marginn {
  margin-left: 21%;
  margin-right: 17%;
}
.b1 {
  display: flex;
  width: 60rem;
  height: 21rem;
  margin: auto;
  border: 1px solid black;
  border-radius: 15px;
  margin-top: 20px;
}
/* 
.b1{
    display: flex;
    width: 60rem;
    height: 21rem;
    margin: 20px 20px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 2/3;
}
.b2{
    display: flex;
    width: 60rem;
    margin: 20px 20px;
    height: 21rem;
    border: 1px solid black;
    grid-column: 3/4;
    border-radius: 15px;
}
.b3{border-radius: 15px;
    display: flex;
    width: 60rem;
    height: 21rem;
    margin: 20px 20px;
    border: 1px solid black;
    grid-column: 2/3;
}
.b4{
    border-radius: 15px;
    display: flex;
    width: 60rem;
    margin: 20px 20px;
    height: 21rem;
    border: 1px solid black;
    grid-column: 3/4;
} */
.shead {
  font-size: 55px;
  text-align: center;
  margin-top: 50px;
}
.hshead {
  text-align: center;
  font-size: 55px;
  margin-left: 10px;
  margin-top: 50px;
}
.phome {
  text-align: center;
  font-size: 25px;
  opacity: 0.9;
}
.p2home {
  font-size: 25px;
  text-align: center;

  opacity: 0.9;
}

.hphome {
  font-size: 25px;
  opacity: 0.9;
  text-align: center;
  margin-left: 3%;
}
.ptext {
  font-size: 25px;
  margin: 20px 0px;
  opacity: 0.6;
  margin-right: 20rem;
}
.contactbtn {
  font-size: 15px;
  width: 187px;
  height: 55px;
  padding: 12px;
  margin-top: 30px;
  background-color: rgba(37, 214, 225, 1);
  color: white;
  font: Inter;
  border: 1px solid rgba(37, 214, 225, 1);
  border-radius: 25px;
}
.Habout {
  font-size: 20px;
  margin-right: 30%;
  margin-top: 30px;
}
.right {
  grid-column: 3/4;
}

.Himg {
  width: 100%;
  height: 100%;
}
.Home_Service_Points{
  font-size:17px;
  font-family: Inter;
  margin-top:10px;
}
.about {
  display: grid;
  grid-template-columns: 12% 1fr 1fr 10%;
  background-color: rgb(246, 254, 254);
}
.aboutimg {
  grid-column: 2/3;
  width: 100%;
  margin: 100px 0px;
}
.aboutxt {
  grid-column: 3/4;
  margin: 100px 0px;
}
.trainingbox1 {
  height: 380px;
  width: 370px;
  border: 1px solid lightgray;
  border-radius: 20px;
  margin: 20px 20px;
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
}

/* .trainingbox2{
    grid-column: 3/4;
    width: 370px;
   
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
}
.trainingbox3{
    grid-column: 4/5;
    width: 342px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
} */
.trainingg {
  /* display: flex;
    flex-flow: row wrap;
    align-content: space-between; */
  /* justify-content: space-between; */
  /* margin: 20px 20px; */
  width: 1300x;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* flex-basis: 50%; */
}
.trainingimage {
  height: 180px;
  width: 340px;
  border-radius: 20px;
}
.Hservice {
  font-size: 25px;
  margin-top: 5px;
}
.habb {
  margin-left: 10px;
  font-size: 50px;
}
.Hblog {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  /* justify-content: space-between; */
  margin: 20px 20px;
}

.slider {
  width: 96%;
}

.right-arrow1 {
  position: absolute;
  top: 50%;
  right: 0%;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow1 {
  position:absolute ;
  top: 50%;
  left: 0%;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media (max-width: 1680px) {
  .margin {
    margin-left: 10%;
    margin-right: 10%;
  }
  .about {
    display: grid;
    grid-template-columns: 9% 1fr 1fr 2%;
    background-color: rgb(246, 254, 254);
  }
}
@media (max-width: 520px) {
  .right {
    display: none;
  }
  .header {
    width: 100%;
    display: grid;
    grid-template-columns: "20rem 2fr 1em ";
  }
  .heading {
    font-size: 45px;
    margin: 0px 0px;
    opacity: 0.8;
  }
  .contactbtn {
    font-size: 10px;
    width: 187px;
    height: 55px;
    padding: 12px;
    margin-top: 30px;
    background-color: rgba(37, 214, 225, 1);
    color: white;
    font: Inter;
    border: 1px solid rgba(37, 214, 225, 1);
    border-radius: 25px;
  }
  .heading2 {
    font-size: 45px;
    margin: 0px 0px;
    color: rgba(37, 214, 225, 1);
  }
  .left {
    grid-column: 1/4;
    margin-top: 50px;
  }
  .p2home {
    display: none;
  }

  .Header {
    height: 400px;
  }

  .ptext {
    font-size: 25px;
    margin: 20px 0px;
    opacity: 0.6;
    margin-right: 0rem;
  }
  .b {
    margin-left: 80px;
  }
  .phome {
    font-size: 14px;
  }
  .shead {
    font-size: 30px;
    margin-left: 15px;
  }
  .Servicebox {
    width: 100%;
    display: grid;
    margin: 20px 20px;
    grid-template-columns: 1fr;
  }
  .servicecont {
    margin: 0px 00px;
  }
  .b1 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .Hservice {
    font-size: 20px;
  }

  .b2 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .b3 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .b4 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .b1image {
    margin: 25px 5px;
    width: 100px;
    height: 100px;
  }
  .hshead {
    font-size: 19px;
    margin-left: 7px;
    margin-top: 50px;
  }
  .hphome {
    font-size: 15px;
    margin-left: 0px;
    opacity: 0.9;
  }

  .trainingbox1 {
    grid-column: 1/2;
    width: 342px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }
  .trainingbox2 {
    grid-column: 1/2;
    width: 343px;
    border: 1px solid lightgray;

    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }

  .trainingbox3 {
    grid-column: 1/2;
    width: 342px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }
  .trainingg {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .about {
    display: grid;
    grid-template-columns: 1fr;
    background-color: rgb(246, 254, 254);
  }
  .aboutimg {
    grid-column: 1/2;
    margin: 100px 0px;
  }
  .Habout {
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .aboutxt {
    grid-column: 1/2;

    margin: 0px 0px;
  }
  .Hab {
    margin-left: 70px;
  }
  .habb {
    margin-left: 25px;
    font-size: 36px;
  }
  .imggg {
    width: 400px;
  }
  .Hblog {
    display: inline;
  }
}

.left {
  margin-left: 40px;
  grid-column: 2/3;
  margin-top: 140px;
}
.heading {
  font-size: 60px;
  margin: 5px 0px;
  opacity: 0.8;
}
.heading2 {
  font-size: 60px;
  margin: 0px 0px;
  color: rgba(37, 214, 225, 1);
}
.Services {
  display: flex;
  justify-content: center;
}
.servicecont {
  margin: 20px 20px;
}
.b1image {
  margin: 25px 25px;
  width: 100px;
  height: 100px;
}
.Servicebox {
  /* display: flex;
    flex-direction: row;
    margin-left:20rem;
    margin-right:20rem; */

  /* display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between; */
  margin: 30px 20px;

  /* width: 100%;
    display: grid;
    
    grid-template-columns:50rem 1fr 1fr 55rem; */
}
.marginn {
  margin-left: 21%;
  margin-right: 17%;
}
.b1 {
  display: flex;
  width: 60rem;
  height: 21rem;
  margin: auto;
  border: 1px solid black;
  border-radius: 15px;
  margin-top: 20px;
}
/* 
.b1{
    display: flex;
    width: 60rem;
    height: 21rem;
    margin: 20px 20px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 2/3;
}
.b2{
    display: flex;
    width: 60rem;
    margin: 20px 20px;
    height: 21rem;
    border: 1px solid black;
    grid-column: 3/4;
    border-radius: 15px;
}
.b3{border-radius: 15px;
    display: flex;
    width: 60rem;
    height: 21rem;
    margin: 20px 20px;
    border: 1px solid black;
    grid-column: 2/3;
}
.b4{
    border-radius: 15px;
    display: flex;
    width: 60rem;
    margin: 20px 20px;
    height: 21rem;
    border: 1px solid black;
    grid-column: 3/4;
} */
.shead {
  font-size: 55px;
  text-align: center;
  margin-top: 50px;
}
.hshead {
  text-align: center;
  font-size: 55px;
  margin-left: 10px;
  margin-top: 50px;
}
.phome {
  text-align: center;
  font-size: 25px;
  opacity: 0.9;
}
.p2home {
  font-size: 25px;
  text-align: center;

  opacity: 0.9;
}

.hphome {
  font-size: 25px;
  opacity: 0.9;
  text-align: center;

  margin-left: 3%;
}
.ptext {
  font-size: 25px;
  margin: 20px 0px;
  opacity: 0.6;
  margin-right: 20rem;
}
.contactbtn {
  font-size: 15px;
  width: 187px;
  height: 55px;
  padding: 12px;
  margin-top: 30px;
  background-color: rgba(37, 214, 225, 1);
  color: white;
  font: Inter;
  border: 1px solid rgba(37, 214, 225, 1);
  border-radius: 25px;
}
.Habout {
  font-size: 20px;
  margin-right: 30%;
  margin-top: 30px;
}
.right {
  grid-column: 3/4;
}

.Himg {
  width: 100%;
  height: 100%;
}
.about {
  display: grid;
  grid-template-columns: 12% 1fr 1fr 10%;
  background-color: rgb(246, 254, 254);
}
.aboutimg {
  grid-column: 2/3;
  width: 100%;
  margin: 100px 0px;
}
.aboutxt {
  grid-column: 3/4;
  margin: 100px 0px;
}
.trainingbox1 {
  height: 341px;
  width: 330px;
  border: 1px solid lightgray;
  border-radius: 20px;
  margin: 20px 20px;
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
}

/* .trainingbox2{
    grid-column: 3/4;
    width: 370px;
   
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
}
.trainingbox3{
    grid-column: 4/5;
    width: 342px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
} */
/* .trainingg{
   
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
   
    margin: 20px 20px;

} */
.trainingimage {
  height: 157px;
  width: 317px;
  border-radius: 20px;
}
.Hservice {
  font-size: 25px;
  margin-top: 5px;
}
.habb {
  margin-left: 10px;
  font-size: 50px;
}
.Hblog {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  /* justify-content: space-between; */
  margin: 20px 20px;
}

.slider {
  width: 96%;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media (max-width: 1680px) {
  .margin {
    margin-left: 10%;
    margin-right: 10%;
  }
  .about {
    display: grid;
    grid-template-columns: 9% 1fr 1fr 2%;
    background-color: rgb(246, 254, 254);
  }
}
@media (max-width: 520px) {
  .right {
    display: none;
  }
  .header {
    width: 100%;
    display: grid;
    grid-template-columns: "20rem 2fr 1em ";
  }
  .heading {
    font-size: 45px;
    margin: 0px 0px;
    opacity: 0.8;
  }

  .Home_Service_Points{
    font-size:12px;
    font-family: Inter;
    margin-top:10px;
  }
  .contactbtn {
    font-size: 10px;
    width: 187px;
    height: 55px;
    padding: 12px;
    margin-top: 30px;
    background-color: rgba(37, 214, 225, 1);
    color: white;
    font: Inter;
    border: 1px solid rgba(37, 214, 225, 1);
    border-radius: 25px;
  }
  .heading2 {
    font-size: 45px;
    margin: 0px 0px;
    color: rgba(37, 214, 225, 1);
  }
  .left {
    grid-column: 1/4;
    margin-top: 50px;
  }
  .p2home {
    display: none;
  }

  .Header {
    height: 400px;
  }

  .ptext {
    font-size: 24px;
    margin: 20px 0px;
    opacity: 0.6;
    margin-right: 0rem;
  }
  .b {
    margin-left: 80px;
  }
  .phome {
    font-size: 14px;
  }
  .shead {
    font-size: 30px;
    margin-left: 15px;
  }
  .Servicebox {
    width: 100%;
    display: grid;
    margin: 20px 20px;
    grid-template-columns: 1fr;
  }
  .servicecont {
    margin: 0px 00px;
  }
  .b1 {
    display: flex;
    width: 40rem;
    height: 15rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .Hservice {
    font-size: 20px;
  }

  .b2 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .b3 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .b4 {
    display: flex;
    width: 40rem;
    height: 20rem;
    margin: 20px 0px;
    border: 1px solid black;
    border-radius: 15px;
    grid-column: 1/2;
  }
  .b1image {
    margin: 25px 5px;
    width: 70px;
    height: 70px;
  }
  .hshead {
    font-size: 19px;
    margin-left: 7px;
    margin-top: 50px;
  }
  .hphome {
    font-size: 15px;
    margin-left: 0px;
    opacity: 0.9;
  }

  .trainingbox1 {
    grid-column: 1/2;
    width: 342px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }
  .trainingbox2 {
    grid-column: 1/2;
    width: 343px;
    border: 1px solid lightgray;

    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }

  .trainingbox3 {
    grid-column: 1/2;
    width: 342px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }
  .trainingg {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .about {
    display: grid;
    grid-template-columns: 1fr;
    background-color: rgb(246, 254, 254);
  }
  .aboutimg {
    grid-column: 1/2;
    margin: 80px 0px;
  }
  .Habout {
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .aboutxt {
    grid-column: 1/2;

    margin: 0px 0px;
  }
  .Hab {
    margin-left: 70px;
  }
  .habb {
    margin-left: 25px;
    font-size: 30px;
  }
  .imggg {
    width: 300px;
    margin-left: 50px;
  }
  .Hblog {
    display: inline;
  }

  .Home_blog_img{
    width: 70%;
    height:70%
  }
}
