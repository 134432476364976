.overview-main-container{
    display: flex;
    justify-content: center;
    width: 100%;

}
.Side-bar{
    
    width: 200px;
    
}
.overview-container{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 85%;
    min-height: 50vh;
    display: flex;
    gap:20px ;

}
.Overview-user{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.Short_name{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #1A53DD;
    display: flex;
    justify-content: center;
align-items: center;
}
.user_name{
color: black;
 font-size: 16px;
 font-family: Inter;
 font-weight: 600;
 word-wrap: break-word;
 margin-top: 15px;
}
.user_Nav_Link{
    color: #949AA7;
font-size: 18px;
font-family: Inter;
font-weight: 400;
word-wrap: break-word;
margin-left: 20px;
cursor: pointer;

transition: font-size .2s ease;
}
.user_Nav_Link:hover{
font-size: 20px;
}
.Left_container{
    /* width: 70%; */
}