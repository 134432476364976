
@import url('../../Font/stylesheet.css');
.mySwiper{
    height: 450px;
    position: relative;
}
.left_btn{
    position: absolute;
    top:40%;
    left:5%
}
.right_btn{
    position: absolute;
    top:40%;
    right:5%
}
.myswiper_cart{
width:300px;
height:200px;
background-color: white;
position: absolute;
top: 15%;
left: 10%;
border-radius: 0px 0px 70px 0px;
padding: 20px;
}
.myswiper_cart_head{
color: black;
 font-size: 30px;
 font-family: Whyte Inktrap;
 font-weight: 700;
 line-height: 32px;
 word-wrap: break-word
}
.myswiper_cart_text{
color: rgba(0, 0, 0, 0.60);
 font-size: 14px;
 font-family: Inter;
 font-weight: 400;
 word-wrap: break-word
}
.Btn{
    color: white;
font-size: 16px;
font-family: Inter;
font-weight: 400;
word-wrap: break-word;
background-color: #1A53DD;
border-radius: 20px;
border: 1px solid #1A53DD;
width: 100px;
height: 35px;
}
.Nav_name.Active {
    color: #1A53DD; /* Color for active item */
  }
.testimonials_carts{
    width: 300px;
    padding:15px;
    border-radius: 20px;
    border:1px solid #FFFFFF;
    background: white;
    margin: 10px;
}

.testimonial_coresal_home{
    max-width:1250px;
    margin-top: 20px;
   
}
.Categry_text{
    color: #1A53DD;
font-size: 24px;
font-family: Inter;
font-weight: 700;
line-height: 33.55px;
word-wrap: break-word;
display: flex;
align-items: center;
}
.Category_container{
 width: 320px;
 margin:20px;
 height: 150px;
 border-radius: 10px;
 background-color: white;
 border: 1px solid #FFFFFF;
 display: flex;
}
@media screen and (max-width:1220px){
    .testimonial_coresal_home{
        max-width:1000px;
       
    }
}
@media screen and (max-width:950px){
    .testimonial_coresal_home{
        max-width:600px;
       
    }
}
@media screen and (max-width:650px){
    .testimonial_coresal_home{
        max-width:330px;
     

    }
    .heading_css{
      text-align: center;  
      margin-bottom: 50px;
    }
    
}