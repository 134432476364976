/* .Service_maincomp{
    margin-top: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 40rem 2fr 4fr 5rem;
    margin-bottom: 50px;
}


.Lcomp{
grid-column: 2/3;
}
.Rcomp{
    grid-column: 3/4;
}
.Service_Content{
    width: 745px;

}


.Sprev{
    width:406px;
    height:90px
}
.Service_Points{
display: flex;
margin-top: 50px;
}
.Simgtt{
    margin-left: 10px;
    margin-top:50px;
    position: absolute;
width: 60px;
height: 60px;
}
.Service_about{
    margin-top: 50px;
    display:flex;
}
.Service_Pointsinside{
    margin-left:20px;
    width:385px
}
.Sercice_info{
    display: flex;
margin-top: 50px;
margin-left: 0px;
}
.Service_ContentImg{
width: 815px;
height: 400px;
}

@media (max-width: 1700px){

    .Service_maincomp{
        margin-top: 50px;
        width: 100%;
        display: grid;
        grid-template-columns: 10rem 2fr 4fr 3rem;
        margin-bottom: 50px;
    }
    

    .Service_ContentImg{
        width: 750px;
        height: 400px;
        }
}

@media (max-width: 1500px){

    .Service_maincomp{
        margin-top: 50px;
        width: 100%;
        display: grid;
        grid-template-columns: 15rem 2fr 4fr 2rem;
        margin-bottom: 50px;
    }
    .Service_ContentImg{
        width: 650px;
        height: 400px;
        }


}
@media (max-width: 1350px){

    .Service_maincomp{
        margin-top: 50px;
        width: 100%;
        display: grid;
        grid-template-columns: 4vw 2fr 4fr 2vw;
        margin-bottom: 50px;
    }
    .Service_ContentImg{
        width: 650px;
        height: 400px;
        }


}

@media (max-width: 500px){
    .Service_maincomp{
        margin-top: 50px;
        width: 100%;
        display: grid;
        grid-template-columns:  1fr 10px ;
        margin-bottom: 50px;
    }
     
    .Lcomp{
        grid-column: 1/2;
        }
        .Rcomp{
            grid-column: 1/2;
            margin-top: 50px;
        }

        .Sprev{
            margin-left: 35px;
            width:306px;
            height:90px
        }
        .Scontact{
          width: 400px;
        }
        .Service_Content{
            width: 400px;
        
        }
        .Service_ContentImg{
            width: 350px;
            height: 200px;
            margin-left: 20px;
        }
        .Service_about{
            margin-top: 50px;
            
            display:inline;
        }
        .Sercice_info{
            display: inline;
            margin-left: 50px;
        margin-top: 50px;
        }
        .Service_Points{
            display: inline;
            margin-top: 50px;
            }
            .Service_Pointsinside{
                margin-top: 50px;
                margin-left:30px;
                width:305px
            }

          
} */
.Service_maincomp {
    margin-top: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 40rem 2fr 4fr 5rem;
    margin-bottom: 50px;
  }
  
  .Lcomp {
    grid-column: 2/3;
  }
  .Rcomp {
    grid-column: 3/4;
  }
  .Service_Content {
    width: 745px;
  }
  
  .Sprev {
    width: 406px;
    height: 90px;
  }
  .Service_Points {
    display: flex;
    margin-top: 50px;
  }
  .Simgtt {
    margin-left: 10px;
    margin-top: 50px;
    position: absolute;
    width: 60px;
    height: 60px;
  }
  .Service_about {
    margin-top: 50px;
    display: flex;
  }
  .Service_Pointsinside {
    margin-left: 20px;
    width: 385px;
  }
  .Sercice_info {
    display: flex;
    margin-top: 50px;
    margin-left: 0px;
  }
  .Service_ContentImg {
    width: 815px;
    height: 400px;
  }
  
  
  .Sevice_header{
    position: relative;
  height: 80vh;
  overflow: hidden;
  }
  .Overlay{
   position:absolute ; 
   width: 100%;
   height: 80vh;
   top: 0;
   background-color: black;
   opacity: 0.7;
  }
  .Service_heading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;
  }
  .s_Header_img{
    height: 80vh;
    animation: scaleImage 30s ease infinite; /* Apply the animation */
  }
  
  @keyframes scaleImage {
    0% {
      transform: scale(1.2); /* Initial scale */
    }
    50% {
      transform: scale(1); /* Scaled size */
    }
    100% {
      transform: scale(1.2); /* Return to the initial scale */
    }
  }
  .S_heading{
  color: white;
  font-family: Inter;
  font-size: 24px;
  }
  .S_heading_text{
    color: white;
    font-family: Inter;
    font-size: 30px;
  }
  .sevice_nav{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  }
  .Service_nac_inner{
  
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10%;
  }
  .Service_nav_item{
    font-size: 20px;
    font-family: Inter;
    cursor: pointer;
    font-weight: 600;
  }
  .Service_nav_item.Active {
    color: #1A53DD; 
    font-size: 22px;
    transition: 0.1s;
  }
  @media (max-width: 1700px) {
    .Service_maincomp {
      margin-top: 50px;
      width: 100%;
      display: grid;
      grid-template-columns: 10rem 2fr 4fr 3rem;
      margin-bottom: 50px;
    }
  
    .Service_ContentImg {
      width: 750px;
      height: 400px;
    }
  }
  
  @media (max-width: 1500px) {
    .Service_maincomp {
      margin-top: 50px;
      width: 100%;
      display: grid;
      grid-template-columns: 15rem 2fr 4fr 2rem;
      margin-bottom: 50px;
    }
    .Service_ContentImg {
      width: 650px;
      height: 400px;
    }
  }
  @media (max-width: 1350px) {
    .Service_maincomp {
      margin-top: 50px;
      width: 100%;
      display: grid;
      grid-template-columns: 4vw 2fr 4fr 2vw;
      margin-bottom: 50px;
    }
    .Service_ContentImg {
      width: 650px;
      height: 400px;
    }
  }
  
  @media (max-width: 500px) {
    .Service_maincomp {
      margin-top: 50px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 10px;
      margin-bottom: 50px;
    }
  
    .Lcomp {
      grid-column: 1/2;
    }
    .Rcomp {
      grid-column: 1/2;
      margin-top: 50px;
    }
  
    .Sprev {
      margin-left: 35px;
      width: 306px;
      height: 90px;
    }
    .Scontact {
      width: 400px;
    }
    .Service_Content {
      width: 400px;
    }
    .Service_ContentImg {
      width: 350px;
      height: 200px;
      margin-left: 20px;
    }
    .Service_about {
      margin-top: 50px;
  
      display: inline;
    }
    .Sercice_info {
      display: inline;
      margin-left: 50px;
      margin-top: 50px;
    }
    .Service_Points {
      display: inline;
      margin-top: 50px;
    }
    .Service_Pointsinside {
      margin-top: 50px;
      margin-left: 30px;
      width: 305px;
    }
  }
  