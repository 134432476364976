/* Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white !important;
  padding: 2px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
  
}
.custom-nav {
    max-width: 200px; /* Adjust the width as desired */
  }
.navbar-logo img {
  height: 40px;
}
.me-2{
  width: 550px !important;
}

.navbar-items {
  display: flex;
  align-items: center;
}

.navbar-btn {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.navbar-categories:hover .navbar-dropdown {
  display: block;
}

.navbar-dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  padding: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.navbar-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.custom-navbar {
  position: sticky;
  top: 0;
  z-index: 9999; /* Set a high z-index value */
}
.navbar-dropdown li {
  padding: 8px 0;
}

.navbar-search input[type="text"] {
  padding: 6px;
  border: none;
  border-radius: 4px;
}

.navbar-search button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
}

.navbar-auth a {
  margin-left: 10px;
  text-decoration: none;
  color: #333;
}

/* Hamburger menu styles */
.navbar-toggle {
  display: none;
}

.navbar-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin-bottom: 5px;
  transition: transform 0.3s ease-in-out;
}

.open .navbar-toggle span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open .navbar-toggle span:nth-child(2) {
  opacity: 0;
}

.open .navbar-toggle span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
.custom-button {
  width: 120px;
  background-color:#1A53DD ;
  border-radius: 50px !important;
  margin: 0px 10px;
}
/* Responsive styles */
@media screen and (max-width:1300px) {
  .me-2{
   width:400px !important;
  }
}
@media screen and (max-width:1240px) {
  .me-2{
   width:300px !important;
  }
}
@media screen and (max-width:1125px) {
  .me-2{
   width:230px !important;
  }
}
@media (max-width: 768px) {
  .navbar-items {
    display: none;
  }

  .navbar-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .navbar-items.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .navbar-items.open .navbar-auth {
    margin-top: 10px;
  }
}
