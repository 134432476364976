.maincomp{
    width: 100%;
    display: grid;
    grid-template-columns: 35rem 2fr 1fr 20rem;
}
.training{
    
    display: grid;
    grid-column: 2/3;
}
.blogcont{
width: 800px;
margin-top: 70px;
box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
border-radius: 20px;
 /* height: 750px;  */
}
.blogcontt{
    width: 800px;
    margin-top: 70px;

    border-radius: 20px;
   
    }
.traininginfoo{
    height: 100vh;
    display: grid;
    grid-column: 3/4;
}
.blogimgg{
    width: 800px;
    height: 400px;
    border-radius: 20px 20px 0px 0px;
    
}
.trapp{
    margin-right:200px
}
.about-blog{
    padding: 35px;
    height: 250px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
margin-top: 50px;
}

.about-blog2{
    padding: 35px;
    height: 600px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
margin-top: 50px;
}
.blogbtn{
   
    font-size: 17px;
    width: 157px;
    height: 45px;
   padding: 12px 40px;
  margin: 50px;   
  
   background-color:   rgba(37,214,225,1);
   color: white;
   font: Inter;
  border: 1px solid  rgba(37,214,225,1) ;
  border-radius: 20px;
}
.comment{
    margin-top: 90px;
    width: 100%;
    display: grid;
    grid-template-columns: 35rem 1fr 1fr 20rem;

}
.com_section{
    display: grid;
    grid-column: 2/3;
}
.comment_Forn{
    margin-left: 150px;
    display: grid;
    grid-column: 3/4;
}
.inpt{
    
    width: 500px;
    height: 50px;
    margin-top: 30px;
    
    border: 1px solid grey;
    border-radius: 5px;
}
.imgtt{
    width: 100px;
    height: 90px;
    border-radius: 5px;
    
}

.Related{
    margin-left:300px;
    margin-right:370px   
}
.Related_Blogs{
    display:flex;
    margin-top:20px
}
.hrsize{
    color: gray;
    width: 700px;
    margin-top: 50px;
    margin-bottom: 50px;
}
@media (max-width: 1700px) {

    
    .maincomp{
        width: 100%;
        display: grid;
        grid-template-columns: 15rem 2fr 1fr 15rem;
    }
}
@media (max-width: 1600px) {

    
    .maincomp{
        width: 100%;
        display: grid;
        grid-template-columns: 15rem 2fr 1fr 8rem;
    }
}
@media (max-width: 500px) {
    .maincomp{
        width: 100%;
        display: grid;
        grid-template-columns: 5rem 1fr;
    }
    .training{
        display: grid;
        grid-column: 2/3;
    }
    .blogcont{
        width: 300px;
        margin-top: 100px;
        box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
        border-radius: 20px;
        height: 700px;
        }
        .blogimgg{
            width: 300px;
            height: 170px;
            
        }
        .about-blog{
            padding: 35px;
            height: 370px;
            width: 300px;
            border-radius: 10px;
            box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
        margin-top: 50px;
        }
        .about-blog2{
            padding: 35px;
            height: 600px;
            width: 320px;
            border-radius: 10px;
            box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
        margin-top: 50px;
        }
    .traininginfoo{
        height: 100%;
        display: grid;
        grid-column: 2/3;
        display: inline;
    }
    .imgg{
        width: 400px;
        height: 350px;
        margin-top: 20px;
        
    }
    .about-training{
        padding: 15px;
        height: 550px;
        width: 300px;
        border-radius: 10px;
        box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
    margin-top: 150px;
    }
    .trap{
        margin-right:0px
    }
    .blogstory{
        width: 350px;
    }
    .Related{
        margin-left:3px;
        margin-right:3px   
    }
    .Related_Blogs{
        display:inline;
        margin-top:20px
    }
    .comment{
        margin-top: 90px;
        width: 100%;
        display: grid;
        grid-template-columns:  1fr ;
    
    }
    .com_section{
        display: grid;
        grid-column: 1/2;
    }
    .comment_Forn{
        margin-left: 15px;
        display: grid;
        grid-column: 1/2;
    }
    .inpt{
    
        width: 300px;
        height: 50px;
        margin-top: 30px;
        margin-left: 30px;
        border: 1px solid grey;
        border-radius: 5px;
    }
    .hrsize{
        color: gray;
        width: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}