.Dash_maincomp{
    width: 100%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 4fr 5rem;
}

.dash_lcomp{
    border-right: 1px solid rgb(211, 206, 206);
    margin-right: 20px;
    grid-column: 1/2;
    margin-left:50px ;
}
.dash_rcomp{
    width: 85%;
    grid-column: 2/3;
 
}
.maindash_enrolled{
    margin-top: 30px;
    display: flex;
 
    width: 400px;
    height: 122px;
background: #FFFFFF;
box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.05);
border-radius: 10px;
}
.Dash_heading{
    margin-left: 20px;
    font-size: 30px;
    font-family: Inter;
    font-weight: 600;
    margin-top: 60px;
    color: #3C3C3B;
}
.dash_usercomp{
    border: 1px solid rgba(37,214,225,1);
    display: flex;
    height: 20rem;
    margin-left: 20px;
    border-radius: 15px;
    background-color: rgba(37,214,225,1);
}
.Admin {
    padding: 10px;
}
.Admin_main{
    display:flex;
    width: 100%;

}

.Admin_btnn {
    width: 150px;
    padding: 8px 20px;
    height: 50px;
    border-radius: 50px;
    outline: none;
    margin-left: 50px;
    border: none;
    font-size: 18px;
  
    color: #fff;
    cursor: pointer;
    background-color: var(--primary);
  }
  
  .Admin_btnn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: rgb(15, 233, 248);
    color: #fff;
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
  }
.Admin_left{
width: 50%;
background-color: rgba(37,214,225,1);
height: 100vh;
/* display: flex; */
align-items: center;
text-align: center;
 /* margin-left: 15%; */
}
.loginButton{
    height: 40px;
    width:350px;
    margin-top: 20px;
   background-color: #3C3C3B;
    
    color: white;
    border: 1px solid black;
    border-radius: 20px;
    font-size: 15px;
}
.Admin_in{
    height: 40px;
    width:350px;
    margin-top: 20px;
    border: 0.5px solid lightgrey;
    border-radius: 10px;
   padding: 10px;
   font-size: 22px;
   font-family: Inter;
   opacity: 0.8;
  
}
.Admin_right{
    /* display: flex; */
    /* align-items: center;
    text-align: center;
   margin-left: 15%; */
}
.updateForm {
    padding-left: 20px;
    margin-top:20px;
     align-items: center;
    text-align: center;
}
.Admin_login{
    /* margin-top: 40%; */
    display: flex;
    
    align-items: center;
    /* text-align: center; */
   
    /* margin-left: 60%; */
}
.updateForm label {
    width: 450px;
    display: inline-block;
    margin-bottom: 5px;
}
.updateForm input {
    width: 300px;
}
.dash_usercom{
    border: 1px solid white;
    max-width: 37%;
    /* height: 30rem; */
    margin-left: 20px;
    border-radius: 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.06); 
    margin-top: 50px;
}
.lecture_head{
    font-size: 23px;
    font-family: Inter;
    margin: 10px 20px;
}

.Lecture_link{
    font-size: 25px;
    font-family: Inter;
   border: 1px solid rgba(37,214,225,1);
    color:  rgba(37,214,225,1);
   margin: 10px 50px;
    text-align: center;
    margin-top: 50px;
    border-radius: 10px;
}
.Lecture_link1{
    font-size: 25px;
    font-family: Inter;
   border: 1px solid red;
    color:  red;
   margin: 10px 50px;
    text-align: center;
    border-radius: 10px;

}


.Lecture_linkk{
    font-size: 25px;
    font-family: Inter;
   border: 1px solid rgba(37,214,225,1);
    color:  rgba(37,214,225,1);
   margin: 10px 50px;
    text-align: center;
    margin-top: 20px;
    border-radius: 10px;
}
.Lecture_link1k{
    font-size: 25px;
    font-family: Inter;
   border: 1px solid red;
    color:  red;
   margin: 10px 50px;
    text-align: center;
    border-radius: 10px;

}
.Lecture_content{
    font-size: 20px;
    font-family: Inter;
}

.dash_nav{
    margin-top: 50px;
}
.dash_navlink{
    margin-left: 10px;
    text-decoration: none;
    margin-top: 70px;
    display: flex;
    color: #3C3C3B;
    opacity: 0.8;
    font-size: 20px;
    font-family: Inter;
    font-weight: 700;

}
.table_margin{
    margin-left: 100px;
}

.table {
  margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .tr{
        border: 2px solid #F3F3F3 !important;
    
    }
  .tablee {
    border: 2px solid #F3F3F3 !important;
    border-radius: 5px;
    width: 700px;
  }
    
  .th {
      width: 500px;
      height: 75px;
    border: 1px solid #F3F3F3 !important;
    background-color: #F3F3F3 !important;
    font-size: 20px;
    font-weight: 600;
    font-family: Inter;
  }
    
  .td {
 
    height: 67px;
    text-align: center;
    font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 17px;
  }
  .profilespace{
      margin-left: 120px;
     font-family:Inter;
      color: black;
    
  }
  
  td.profilespace{
      width: 70%;
  }
  .dash_icons{
      margin-right: 20px;
  }
  .profiletext{
      height: 80px;
  width:0%;
    
      font-size: 25px;
      font-family: Inter;
      font-weight: 600;
      color: #3C3C3B;
  }
.maindash_view{
    display: flex;
}
.verline{

}

.Enrolled_trainingbox2{
   
    width: 382px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
}
.Enrolled_trainingimage{
    height: 200px;
      width: 380px;
  border-radius: 20px;
      
  }
  @media (max-width: 500px){
    .Dash_maincomp{
        width: 100%;
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr ;
    }

    .dash_lcomp{
        border-right:none;
        margin-right: 0px;
        grid-column: 1/2;
        margin-left:10px ;
    }
    .dash_rcomp{
        grid-column: 1/2;
        margin-top: 50px;
     
    }
    .dash_nav{
        margin-top: 20px;
       
        
    }
    .dash_icons{
        margin-right: 5px;
        width: 23px;
        height: 23px;
    }
    .dash_navlink{
        margin-left: 0px;
        margin-top: 30px;
        margin-right: 25px;
        display: inline;
        color: #3C3C3B;
    opacity: 0.9;
        font-size: 12px;
        font-family: Inter;
        font-weight: 700;
    
    }
    .dash_usercomp{
        border: 1px solid rgba(37,214,225,1);
        display: flex;
        height: 20rem;
        width: 350px;
        margin-left: 20px;
        border-radius: 15px;
        background-color: rgba(37,214,225,1);
    }
    .maindash_view{
        display: inline;
        
    }
    .maindash_enrolled{
        margin-top: 30px;
        display: flex;
     
        width: 400px;
        height: 132px;
    background: #FFFFFF;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    }

    .table {
        margin-top: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
          .tr{
              border: 2px solid #F3F3F3;
          
          }
        .tablee {
          border: 2px solid #F3F3F3;
          border-radius: 5px;
          width: 330px;
          margin-bottom: 50px;
        }
          
        .th {
            width: 110px;
            height: 75px;
          border: 1px solid #F3F3F3;
          background-color: #F3F3F3;
          font-size: 20px;
          font-weight: 600;
          font-family: Inter;
        }
          
        .td {
       
          height: 67px;
          text-align: center;
          font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
        }

        .table_margin{
            margin-left:0px;
        }
        .profiletext{
            margin-left: 20px;
            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 15px;
            font-family: Inter;
            font-weight: 500;
            color: #3C3C3B;
        }
        .profilespace{
            margin-left: 10px;
           font-family:Inter;
            color: black;
          
        }
        .profilespace1{
            margin-left: 60px;
           font-family:Inter;
            color: black;
          
        }
        .profilespace2{
            margin-left: 45px;
           font-family:Inter;
            color: black;
          
        }
        .profilespace3{
            margin-left: 90px;
           font-family:Inter;
            color: black;
          
        }
        .profilespace4{
            margin-left: 18px;
           font-family:Inter;
            color: black;
          
        }
        .profilespace5{
            margin-left: 17px;
           font-family:Inter;
            color: black;
          
        }
        .profilespace6{
            margin-left: 110px;
           font-family:Inter;
            color: black;
          
        }
        .Enrolled_trainingbox2{
   
            width: 342px;
            border: 1px solid lightgray;
            border-radius: 20px;
            margin: 20px 20px;
            box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
        }
        .Enrolled_trainingimage{
            height: 160px;
              width: 340px;
          border-radius: 20px;
              
          }
  }