.Service_main_Container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.Service_inner_Container {
  width: 80%;
}
.SI_section1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  width: 100%;
  margin-top: 80px;
}
.SI_Left {
  width: 45%;
}
.SI_right {
  width: 45%;
}
.Service_Heading {
  font-size: 30px;
  font-family: Inter;
}
.Service_text {
  margin-top: 10px;
  font-size: 20px;
  font-family: Inter;
}

.contact_banner_container {
  width: 90%;
  position: relative;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.Contact_banner_text {
  position: absolute;
  top: 50px;
  left: 50px;
}
.challenges_box {
  width: 300px;
  /* height: 150px; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 15px;
}

.Service_contact_Heading {
  font-size: 35px;
  font-family: Inter;
}
@media screen and (max-width: 600px) {
  .SI_Left {
    width: 100%;
  }
  .SI_right {
    width: 0%;
  }
  .Service_Heading {
    font-size: 24px;
    font-family: Inter;
  }
  .Service_contact_Heading {
    font-size: 20px;
    font-family: Inter;
  }
  .Contact_banner_text {
    position: absolute;
    top: 15px;
    left: 50px;
  }
}
