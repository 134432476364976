
.maindiv{
    width: auto;
    height: 80rem;
    display: flex;
  margin-top: 15vh;
  margin-left: 10vh;


}
.contactform{
    background-color: white;
border:  1px solid white;
background-color: white;
margin-right: 150px;
margin-top: 0;
border-radius:20px;
display: flex;
justify-content: right;
box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
width: 60rem;
height: 70rem;
}
.input{
    width: 400px;
    height: 40px;
    margin-top: 30px;
    margin-left: 50px;
    border: 1px solid black;
    border-radius: 5px;
}
.Minput{
    width: 400px;
    height: 120px;
    margin-top: 30px;
    margin-left: 50px;
    border: 1px solid black;
    border-radius: 5px;
}
::placeholder {
   font-size: large;
   padding: 15px;
  }
.contactbtnn{
    margin-left: 50px;
    font-size: 18px;
    width: 157px;
    height: 45px;
   padding: 12px;
   margin-top: 30px;
   background-color:   rgba(37,214,225,1);
   color: white;
   font: Helvetica Now Display;
  border: 1px solid  rgba(37,214,225,1) ;
  border-radius: 5px;
}
.text{
width: 90rem;
}
.textl{
    font-size: 50px;
    margin-top: 20rem;
    margin-left: 10rem;
    font-weight: bold;
    color: white;
}
.texts{
    font-size: 20px;
    margin-top: 5rem;
    margin-left: 10rem;
    margin-right: 10rem;
    font-weight: bold;
    color: white;
}
@media (max-width: 520px) {
    .form{
        background-color: white;
    border:  1px solid white;
    background-color: white;
    margin-right: 150px;
    margin-top: 0;
    border-radius:20px;
    display: flex;
    justify-content: left;    
    width: 50rem;
    height: 70rem;
    }
    .maindiv{
      
        height: 80rem;
        display: flex;
        margin-left: 0;
        margin-top: 0;

    
    
    }
 
    .text{
       display: none;
        }
        .contactbtn{
            margin-left: 50px;
            font-size: 15px;
            width: 157px;
            height: 45px;
           padding: 12px;
           margin-top: 30px;
           background-color:   rgba(37,214,225,1);
           color: white;
           font: Helvetica Now Display;
          border: 1px solid  rgba(37,214,225,1) ;
          border-radius: 15px;
        }
        .input{
            width: 300px;
            height: 40px;
            margin-top: 30px;
            margin-left: 50px;
            border: 1px solid black;
            border-radius: 5px;
        }
        .Minput{
            width: 300px;
            height: 120px;
            margin-top: 30px;
            margin-left: 50px;
            border: 1px solid black;
            border-radius: 5px;
        }
}