.header_container{
    width: 100%;
    height: 53vh;
    background-color: #1F1F1F;
    position: relative;
}
.header_content{
  padding-top:3%;
  padding-left: 10%;
  width:40%
}
.header_text{
    
     color: white;
     font-size: 16px;
     font-family: Whyte Inktrap;
     font-weight: 600;
     line-height: 24.45px;
     word-wrap: break-word
}

.training_input{
    width: 300px;
    height: 36px;
    background-color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 2px;
    font-size: 18px;
    font-family: Inter;
    margin: 10px;
}
.header_heading{
    color: white;
    font-size: 32px;
    font-family: Whyte Inktrap;
    font-weight: 700;
    line-height: 40.26px;
    word-wrap: break-word
}

.training_cart{
width: 250px;
background-color: white;
height: 330px;
padding: 10px;
position: absolute;
top: 35%;
right: 20%;
border-radius: 20px;
box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5)

}


@keyframes rotate {
   100% {
       transform: rotate(1turn);
   }
}

.conic {
	position: relative;
	z-index: 0;
	width: 400px;
	height: 300px;
	margin: 20px;
	border-radius: 10px;
	overflow: hidden;
	padding: 2rem;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: rgb(84, 180, 38);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-image: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
	}
}

.conic-demo::after {
	animation: opacityChange 5s infinite linear;
}

@keyframes opacityChange {
	50% {
		opacity:.5;
	}
	100% {
		opacity: 1;
	}
}
.L_pay_btn{
    width: 150px;
    height: 40px;
    background-color: rgb(152, 243, 109);
    color: white;
    font-size: 15px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    border:1px solid rgb(84, 180, 38);
    border-radius: 5px;
}
.pay_btn{
width: 150px;
height: 35px;
background-color: #1A53DD;
color: white;
font-size: 15px;
font-family: Inter;
font-weight: 400;
word-wrap: break-word;
border:1px solid #1A53DD;
border-radius: 20px;
margin-left: 35px;
margin-top: 5px;
margin-bottom: 15px;
}
.cart_share{
    color: black;
font-size: 14px;
font-family: Inter;
font-weight: 400;
text-decoration: underline;
line-height: 26.61px;
word-wrap: break-word;
padding: 0px 10px;
}
.cart_amount{
    color: black;
font-size: 19px;
font-family: Inter;
font-weight: 700;
line-height: 27.96px;
word-wrap: break-word;
margin-left: 10px;
}
.training_detail{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.training_section1{
    max-width: 1000px;
    padding: 20px;
    width: 100%;
    margin-top: 15vh;
}
.training_section1_heading{
color: black;
 font-size: 24px;
 font-family: Whyte Inktrap;
 font-weight: 700;
 line-height: 24.67px;
 word-wrap: break-word
}
.training_section1_text{
color: rgba(0, 0, 0, 0.60);
 font-size: 16px;
 font-family: Inter;
 font-weight: 400;
 line-height: 29.93px;
 word-wrap: break-word
}
.training_section2{
   
    border: 1px solid black;
    border-radius: 15px;
    width: 100%;
    margin: 20px;
    padding: 30px;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 70px;
}

.training_text_container{
    width: 450px;
    padding: 5px;
}
.training_instructure{
    display: flex;
    max-width: 1000px;
    justify-content: center;
    flex-wrap: wrap;


}
@media screen and (max-width:700px) {
    .Mobile_change{
       display: none;
    }
    .header_content{
        padding-top: 50px;
        width: 100%;
    }
}