.About_comp{
    width:100%;
    margin-bottom:150px ;
    display: grid;
    margin-top:150px;
    grid-template-columns: 15rem 1fr 1fr 25rem;
}
.About_lcomp{
    grid-column:2/3;
}
.About_rcomp{
    grid-column:3/4;
}
.About_head{
    margin-top: 20px;
font-size: 48px;
font-family: Inter;
font-weight: 600;

}
.About_info{
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    /* margin-right: 25rem; */
    margin-top: 30px;
    
}
.About_nav{
    margin-top: 50px;
    margin-bottom: 20px;

}
.About_navcomp{
font-size: 20px;
font-family: Inter;
font-weight: 600;
margin-left: 0px;
text-decoration: none;
color: black;
}
.swal2-popup {
    /* max-height: 300px !important; */
    max-width: 300px !important;
  }
  .swal2-icon {
    font-size: 13px !important;
  }
  
  .swal2-text {
    max-height: 70px;
  }

  .swal2-title {
    font-size: 27px !important;
  }
  .swal2-html-container {
    font-size: 20px !important;
    margin: 1em 1em 0.3em !important;
  }

  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #7066e0;
    color: #fff;
    font-size: 1.7em !important;
}
.swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #6e7881;
    color: #fff;
    font-size: 1.7em !important;
}
  
  .swal2-confirm {
    background: #3aa9a9 !important;
  }
.dashboard_button{
    margin-left: 120px;
    font-size: 18px;
    margin-bottom: 20px;
    width: 157px;
    
    float: right;
    height: 45px;
   padding: 10px 35px;
   margin-top: 30px;
   background-color:  #4BB543;
   color: white;
   font: Helvetica Now Display;
  border: 1px solid  #4BB543 ;
  border-radius: 5px;
}
.dashboard_button:hover{
    color:white
}
.About_navcomp:hover {
    color:  #1A53DD;
    
     border-radius: 4px;
     transition: all 0.2s ease-out;
   }

   .About_hr{
    width:500px;
    margin-left:0px
}
.Term{
    margin-right:200px;
    margin-left:200px
}
   @media (max-width: 500px){
    .About_comp{
        width:100%;
        margin-bottom:100px ;
        display: grid;
        margin-top:100px;
        grid-template-columns: 1fr;
    }
    .About_lcomp{
        grid-column:1/2;
 
    }
    .About_rcomp{
        grid-column:1/2;
        
    }

    .About_head{
        margin-left: 10px;
        margin-top: 20px;
    font-size: 38px;
    font-family: Inter;
    font-weight: 400;
    
    }
    .About_info{
        width:380px;
        margin-left: 20px;
        font-size: 20px;
        font-family: Inter;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
     
        margin-top: 30px;
        
    }
    .About_nav{
        margin-top: 50px;
        margin-bottom: 20px;
    
    }
    .About_navcomp{
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    margin-left: 10px;
    color: black;
    }
    .About_hr{
        width:350px;
        margin-left:5px
    }
    .About_Img{
        margin-top: 30px;
        width: 400px;
        height: 350px;

    }

    .Term{
        margin-right:20px;
        margin-left:20px
    }
   }