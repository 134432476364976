.n_container1{
width: 100%;
display: flex;
justify-content: center;
flex-direction: column;
background-image: url('./Img/Dackground.png');
height: auto;
position: relative;
align-items: center;
}
.n_container2{
    position: absolute;
    top: 100px;
width: 80%;
display: flex;
position: relative;
margin-bottom: 50px;
}
.tech_logo{
    position: absolute;
    top:70%;
    left:20px ;
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 5%;
    flex-wrap: wrap;
}
.TectImg_text{
font-size: 32px;
font-family: Inter;
position: absolute;
top: 40px;
left: 40px;
color: white;
text-shadow: 0 0 4px #fff;
}
.TectImg_text1{
    font-size: 15px;
    font-family: Inter;
    position: absolute;
    top: 90px;
    left: 40px;
    color: white;
    text-shadow: 0 0 4px #fff;  
}
.teach_p_img{
    width: 70%;
    height: 320px;
    border-radius: 20px;
}
.Back_flip_container{
padding: 1rem;
}
.Back_flip{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 0px !important;
    background: rgb(86,179,32);
    background: linear-gradient(0deg, rgba(86,179,32,1) 0%, rgb(203, 231, 187) 30%, rgb(231, 250, 220) 60%, rgba(255,255,255,1) 100%);
}
.Case_background{
    background-image: url('./Img//Sade\ BG.png');
}
.flip_card{
    height: 250px;
    width: 200px;
    border-radius: 20px;
}
.flip_img{
    width: 100px;
    mix-blend-mode:multiply;
    margin-top:50px ;
  margin-left: 40px;
}
.case_cart{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    flex-wrap: wrap;
    gap: 40px;
    border-radius: 20px;
   
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.n_container2_l{
   margin-top: 7%;
    width: 40%;

}
.n_container2_r1{
    width: 60%;
    padding-top: 2%;
}
.n_container2_r{
    width: 60%;
    padding-top: 2%;
}
.n_container2_head{
font-size: 35px;
font-weight:600;
font-family: Inter;
}
.n_container2_text{
    font-size: 20px;
    font-family: Inter;
}
.n_container3{
width: 100%;
margin-top:50px;
display: flex;
justify-content: center;
}
.n_container3_inner{
width: 80%;
display: flex;
gap: 50px;
}
.n_container3_l{
    padding: 15px;
    width: 45%;
}
.n_container3_r{
    width: 50%;
    padding: 10px;

}
.About_home_css{
    margin-top: 7%;
    width: 50%;
}

@media screen and (max-width:1400px){
    /* .n_container2{
      margin-top: 100px;
    } */
    .n_container2{
        position: absolute;
        top: 100px;
    width: 90%;
    display: flex;
    position: relative;
    margin-bottom: 50px;
    }
   
}
@media screen and (max-width:1237px){
    .n_container2_r1{
        justify-content: center;
    }
    .tech_logo{
        top: 50%;
    }
    
}
@media screen and (max-width:960px){
    .TectImg_text1{
        display: none;
    }
    .n_container2_r{
       display: none;
       width: 0%;
    }
    .TectImg_text{
        font-size: 24px;
    }
    .n_container3_inner{
        gap: 10px;
    }
    .tech_logo{
        top: 40%;
    }
    .n_container2_r1{
        width: 90% !important;
     }
    .n_container2_l{
        margin-top: 5%;
         width: 100% !important;
     
     }
     .About_home_css{
       display: none;
    }
     .n_container3_r{
        display: none;
        width: 0%;
     }
     .n_container3_l{
        width: 100%;
     }
     .n_container1 {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-image: url(http://localhost:3000/static/media/Dackground.c2a704f….png);
        height: auto;
        position: relative;
        align-items: center;
    }
@media screen and (max-width:470px){
    .tech_logo{
top: 32%;
    }
    .TectImg_text{
        top: 20px;
    }
}
}