.Footer_main_cmponenet{
    background-color: #263238;
    min-height: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.footer_container_content{
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    /* justify-content: center; */
}
.footer_link_containr{
    width: 200px;
     padding: 10px;
    text-decoration: none;
}
.fotter_link{
    color: white;
font-size: 16px;
text-decoration: none;
font-family: Inter;
font-weight: 400;
line-height: 35.74px;
word-wrap: break-word
}
@media screen and (max-width:600px){
    .mobile_fotter_link{
        margin-top: 100px;
    }
    .fotterlogo_place{
     bottom: 125px !important; 
     left:  55% !important;  
    }
}