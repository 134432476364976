
  
 
  
  .event_btn {
    width: 300px;
    height: 35px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    font-size: 15px;
    font-family: Inter;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .event_heading{
    font-size: 30px;
    font-family: Inter;
    text-align: center;
  }

  .event_text{
    font-size: 18px;
    font-family: Inter;
    text-align: center;
  }
.event_input{
    width: 300px;
    height: 35px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 10px;
    font-size: 15px;
    font-family: Inter;
}
.event_section1{
    margin-top: 100px;
}
.Backgorund_sade{
    background-image: url('./Img//Sade.png');
    margin-bottom: 50px;
}

@media screen and (max-width:800px) {
  .EventMobileImage{
    display: none;
  }
}