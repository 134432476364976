.webinar_headerMain{
    background-image: url('./images//header.png');
    background-position: center;
background-repeat: no-repeat;
position: relative;
background-size: cover;
    width: 100%;
    height: auto;
    min-height: 400px;
    position: relative;
}
.webinar_headContainer{
position: absolute;
width:400px;
top: 30%;
left: 10%;
}
.webinar_head_text{
    color: white;
    font-size: 30px;
    font-family: Inter;
}
img.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
img.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
