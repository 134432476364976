/* @import 'bootstrap/dist/css/bootstrap.min.css'; */
.maincomp {
    width: 100%;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 35rem 2fr 1fr 20rem;
  }
  .Payment_page_discription{
    width: 600px;
    margin-top: 4%;
    padding-top: 20px;
    padding-bottom: 20px;
   
  }
  .fee_text{
    font-size: 30px;
    color: white;
    font-family: Inter;
    font-weight: 600;
    text-shadow: 0 0 4px white;
  }
  .Modal_head{
    text-align: center;
    
}
.Modal_heading{
  
    font-size: 35px;
    font-family: Inter;
    font-weight: 600;
    color: gray;

}
.Modal_Duration_Name{
    font-size: 25px;
    font-family: Inter;
    font-weight: 550;
    margin-top: 15px;
}
.Modal_hr{
    width:75%;
    margin-left:12%;
    margin-top: 30px;
    
}
.Modal_Duration_btn{
    height: 25px;
    width: 25px;
    margin-top: 15px;

}
.Consultant_Modal_image{
    margin-top: 15px;
    margin-left: 15px;
    max-width:200px;
    height: 120px;
}
.Duration_show{
    width: 40px;
    height: 25px;
    margin-top: 15px;

    text-align: center;
}
.Modal_Name{
    font-size: 25px;
    font-family: Inter;
    font-weight: 550;
    margin-top: 15px;
    margin-left: 20px;
}
.Modal_ans{
    font-size: 25px;
    font-family: Inter;
    font-weight: 650; 
    opacity: 0.7;

}
.Modal_l_Name{
    font-size: 25px;
    font-family: Inter;
    font-weight: 550;
    margin-top: 15px;
  

}
.Modal_head_ans{
    font-size: 35px;
    font-family: Inter;
    font-weight: 650;
   
    }
    .Modal_Links{
        font-size: 22px;
        font-family: Inter;
        color: rgba(37, 214, 225, 1);
        margin-left: 20px;
    }
  .payment_label_type{
    font-size: 25px;
    font-family: Inter;
    opacity: 0.9;
    margin-top: 20px;
  }
  .payment_input_type{
    width: 300px;
    height: 40px;
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 10px;
    font-size: 22px;
    border-radius: 5px;
    border: solid 1px black;
    font-family: Inter;
  }
  .training {
    display: grid;
    grid-column: 2/3;
  }
  .swal2-popup {
    /* max-height: 300px !important; */
    max-width: 400px !important;
  }
  .About_Consultant_box{
    width: 70%;
  }
  .Consultant_info{
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    margin-top: 20px;
    color: #6e7881;
  
  }
  .payment_form_section{
    margin-top: 4%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
  margin-bottom: 20px;
  }
  .plus-minus-input {
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
            margin-top: 15px;
  }
  .uppergap{
    margin-top: 20px;
  }
  .plus-minus-input .input-group-field {
  
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1rem;
  }
  
  .Increment_btn{
    margin-left: 10px;
    font-size: 18px;
    width: 200px;
    margin-top: 20px;
    height: 45px;
    padding: 12px;
    background-color: rgba(37, 214, 225, 1);
    color: white;
    font: Helvetica Now Display;
    border: 1px solid rgba(37, 214, 225, 1);
    border-radius: 5px;
  }
  
  .plus-minus-input .input-group-field::-webkit-inner-spin-button,
  .plus-minus-input .input-group-field ::-webkit-outer-spin-button {
    -webkit-appearance: none;
            appearance: none;
  }
  
  .plus-minus-input .input-group-button .circle {
    border-radius: 20%;
    padding: 0.25em 0.8em;
  }
  
  
  .swal2-icon {
    font-size: 13px !important;
  }
  
  .swal2-text {
    max-height: 70px;
  }
  .swal2-popup {
    width:40em !important;
  }
  .swal2-title {
    font-size: 27px !important;
    font-family: Inter !important;
  }
  .swal2-html-container {
    font-size: 20px !important;
    margin: 1em 1em 0.3em !important;
  }
  .swal2-html-container {
    font-family: Inter;
  }
  
  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #7066e0;
    color: #fff;
    font-size: 1.7em !important;
  }
  .swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #6e7881;
    color: #fff;
    font-size: 1.7em !important;
  }
  
  .swal2-confirm {
    background: #3aa9a9 !important;
  }
  
  .traininginfo {
    display: grid;
    grid-column: 3/4;
  }
  
  .Search_inp{
    width: 300px;
    margin-top: 100px;
    border: 1px solid white;
    height: 55px;
    font-size: 23px;
    font-family:Inter;
    padding: 10px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
    border-radius: 10px;
  
  }
  .imggt {
    width: 80%;
    height: 350px;
    margin-top: 20px;
    border-radius: 30px;
  }
  .D_margin{
  margin-top:20px;
  }
  .trap {
    margin-right: 200px;
    margin-left: 50px;
    font-size: 20px;
  }
  .about-training {
    padding: 35px;
  
    width: 400px;
    border-radius: 10px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
    margin-top: 150px;
  }
  .contactbtn {
    margin-left: 0px;
    font-size: 14px;
    width: 157px;
    height: 45px;
    padding: 12px;
    margin-top: 10px;
    background-color: rgba(37, 214, 225, 1);
    color: white;
    font: Helvetica Now Display;
    border: 1px solid rgba(37, 214, 225, 1);
    border-radius: 5px;
  }
  .imgt {
    width: 100px;
    height: 70px;
    border-radius: 5px;
  }
  
  .maintrainingcomp {
    width: 100%;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 40rem 1fr 2fr 35rem;
  }
  .trainingcheck {
    grid-column: 2/3;
  }
  .Maintraining {
    margin-top: 150px;
    display: grid;
    grid-column: 3/4;
    grid-template-columns: 1fr 1fr;
  }
  .maintrainingbox2 {
    grid-column: 2/3;
    width: 370px;
    height: 400px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }
  .maintrainingbox1 {
    grid-column: 1/2;
    width: 370px;
    height: 400px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin: 20px 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
  }
  .training_names {
    margin-top: 30px;
    display: flex;
  }
  .training_heading {
    font-size: 50px;
    margin-top: 50px;
    font-weight: 600;
  }
  
  @media (max-width: 1600px) {
    .maintrainingcomp {
      width: 100%;
      margin-bottom: 100px;
      display: grid;
      grid-template-columns: 10rem 1fr 2fr 15rem;
    }
  }
  @media (max-width: 500px) {
    .maincomp {
      width: 100%;
      display: grid;
      grid-template-columns: 0rem 1fr;
    }
    .training {
      display: grid;
      grid-column: 2/3;
    }
    .traininginfo {
      display: grid;
      grid-column: 2/3;
      display: inline;
    }
    .imggt {
      width: 300px;
      height: 150px;
      margin-top: 20px;
    }
  
    .about-training {
      padding: 15px;
  
      width: 300px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      margin-top: 150px;
    }
    .trap {
      margin-right: 0px;
      margin-left: 0px;
      font-size: 17px;
    }
  
    .maintrainingcomp {
      width: 100%;
      margin-bottom: 100px;
      display: grid;
      grid-template-columns: 1fr;
    }
  
    .Maintraining {
      margin-top: 150px;
      display: grid;
      grid-column: 1/2;
      grid-template-columns: 1fr;
    }
    .training_heading {
      font-size: 20px;
      margin-top: 50px;
      font-weight: 600;
    }
    .training_names {
      margin-top: 15px;
      display: flex;
    }
    .trainingcheck {
      margin-left: 40px;
      grid-column: 1/2;
    }
    .maintrainingbox2 {
      grid-column: 1/2;
      width: 370px;
      height: 400px;
      border: 1px solid lightgray;
      border-radius: 20px;
      margin: 20px 20px;
      box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
    }
    .maintrainingbox1 {
      grid-column: 1/2;
      width: 370px;
      height: 400px;
      border: 1px solid lightgray;
      border-radius: 20px;
      margin: 20px 20px;
      box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05);
    }
  }
  