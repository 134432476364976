.Progress_heading{
color: black;
 font-size: 28px;
 font-family: Inter;
 font-weight: 700;
 letter-spacing: 0.10px;
 word-wrap: break-word;
 margin-bottom: 20px;
}
.progress_container{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.progres_cart{
    background-color: #FDE0E5;
    border-radius: 20px;
    height: 180px;
    width: 150px;
    position: relative;
    cursor: pointer;
}
.cart_name{
    color: black;
font-size: 16px;
font-family: Inter;
font-weight: 500;
word-wrap: break-word;
margin-left: 20px;
position: absolute;
bottom: 40px;
}
.cart_type{
    color: #A15965;
font-size: 14px;
font-family: Poppins;
font-weight: 400;
line-height: 17px;
letter-spacing: 0.10px;
word-wrap: break-word;
margin-left: 20px;
position: absolute;
bottom: 17px;
}
.link_container{
    height: 400px;
    width: 370px;
    color: white;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    overflow: scroll;
    overflow-x: hidden;
}