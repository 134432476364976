.login-form {
    max-width: 300px;
    margin: 0 auto;
    height: 80vh;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color:white;
    border-radius: 20px;
    padding: 5px;
    border: 1px solid #E0E2E9;
    height: 40px;
  }
  
  .input-group .icon {
    display: flex;
    align-items: center;
    padding-left:10px;
    color: #E0E2E9;
  }
  
  .input-group input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    /* padding: 10px; */
    color: #333;
    padding: 0px 10px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: white;
  
    border: none;
    border-radius: 30px;
  }
  
  .forgot-password {
    margin-top: 10px;
    color: #4267B2;
    font-weight: 700;
    margin-left: 80px;
  }
  
  .google-btn,
  .facebook-btn {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border: none;
    color: #333;
    margin-top: 10px;
  }
  
  .google-icon {
    color: #db4437;
  }
  
  .facebook-icon {
    color: #4267B2;
  }
  
  .icon {
    margin-right: 10px;
    font-size: 20px;
  }
  