.adminPanelContainer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif !important;
  font-size: 12px;
  height: 100%;
  min-width: 1000px;

  display: flex;

  .adminLoginArea {
    padding: 20px;

    width: 50%;

    // input {
    //     display: block;
    //     margin-bottom: 5px;
    //     width: 200px; height: 25px;
    //     padding-left: 10px;
    // }
  }
  .Admin_panel {
    display: flex;
    width: 100vw;
  }
  .AdminPanel_Left {
    width: 50%;
    background-color: rgba(37, 214, 225, 1);
    height: 100vh;
    text-align: center;
  }
  .Admin_img2 {
    margin-top: 40%;
  }
  .Admin_panel_Right {
    text-align: center;
    margin-top: 15%;
  }


  .Admin_List{
    font-family: Inter;
    color: gray;            
    font-size: 20px;
}
  .contentContainer {
    width: 100%;
    // background-color: #aaa;
    display: flex;

    .sideBar {
      width: 200px;
      // background-color: #aaa;
      border-right: 1px solid #ccc;

      .logo {
        height: 85px;
        line-height: 60px;
        overflow: hidden;
        // background-color: #eee;
        border-bottom: 1px solid #ccc;
        text-align: center;

        .navHome {
          width: 200px;
          font-size: 15px;
        }
      }

      .nav {
        padding-top: 10px;

        .main,
        .list {
          border-bottom: 1px solid #ccc;
          padding: 5px;
          font-size: 25px;
          font-family: Inter;
          font-weight: 550;

          ul li {
            padding: 10px;
            padding-left: 15px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
              color:rgba(37, 214, 225, 1) ;
             
            }

            .icons {
              margin-right: 10px;
              font-family: Inter;
              font-weight: 600;    
              color:#000;          
              font-size: 25px;
            }

          }
        }

        .list {
        }
      }
    }

    .contentSection {
      // border-bottom: 1px solid #ccc;
      width: 100%;

      .header {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .searchContainer {
          width: 300px;
          height: 40px;
          line-height: 30px;
          border-radius: 10px;
          border: 1px solid black;
          display: flex;
          align-items: center;
          margin-left: 5%;

          .searchInput {
            width: 100%;
            border: none;

            outline-style: none;
            padding-left: 10px;
          }

          .searchIcon {
            font-size: 20px;
            cursor: pointer;
            color: rgba(37, 214, 225, 1);
            width: 30px;
          }
        }

        .dateTimeContainer {
          margin-right: 10px;
          font-family: Inter;
          font-size: 22px;
          font-weight: bold;
        }
      }

      .mainContent {
        padding: 10px;
      }
    }
  }
}
