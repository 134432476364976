.Admin_Trainer{
    display: flex;
}
.Admin_Trainer_left{
    width: 30%;
}
.Admin_trainer_label{
    font-size: 23px;
    font-family: Inter;
}
.Admin_trainer_input{
width: 300px;
height: 35px;
margin-top: 10px;
border-radius: 10px;
font-family: Inter;
font-size: 23px;
padding: 10px;
}
.Admin_submit_btn{
    width: 100px;
    font-size: 21px;
    font-family: Inter;
    border: 5px;
    margin-top: 50px;
    float: right;
    background-color: white;
    cursor: pointer;

}
.Admin_submit_btn:hover{
    color: rgba(37, 214, 225, 1);
    font-size: 25px;
    transition: all 0.2s ease-out;
}
.Trainer_submit_btn{
    width: 100px;
    font-size: 21px;
    font-family: Inter;
    border: 5px;
    margin-top: 10px;
    float: right;
    background-color: white;
    cursor: pointer;


}
.Trainer_submit_btn:hover{
    color: rgba(37, 214, 225, 1);
    font-size: 25px;
    transition: all 0.2s ease-out;
}
.MAke_trainer{
    width: 30%;
}