.newBlogContent {
    padding: 20px;

    .blogInputContainer {
        margin-bottom: 20px;

        input, textarea {
            display: block;
            width: 400px; height: 25px;
            padding-left: 15px;
        }
    }

    #newBlogForm {
        .categories, .contents, .headings {
            display: flex; gap: 15px;
            margin-bottom: 5px;

            .btn-add {
                margin-top: 5px;
            }
        }


        .newBlogSubmit {
            padding-left: 15px; padding-right: 15px;
        }
    }
}

.New_blog_Label{
font-size: 22px;
font-family: Inter;
font-weight: 550;
}