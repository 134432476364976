.viewEditContainer {
    padding: 20px;

    .blogInputContainer {
        margin-bottom: 20px;

        input, textarea {
            display: block;
            width: 400px; height: 25px;
            padding-left: 15px;
        }
    }

    #viewEditBlogForm {
        .categories, .contents, .headings {
            display: flex; gap: 15px;
            margin-bottom: 5px;

            .btn-add {
                margin-top: 5px;
            }
        }


        .newBlogSubmit {
            padding-left: 15px; padding-right: 15px;
        }
    }
}

