.Trainer_Main_comp{
    margin-top: 30px;
    margin-left: 30px;
    display: flex;
}
.Trainer_name_comp{
    width: 30%;
}
    
.Trainer_name_select{
    font-size:25px;
    font-weight: 550;
    color: gray;
    font-family: Inter;
    margin-bottom: 7px;
}
.Trainer_name_select:hover {
    color: rgba(37, 214, 225, 1);
    cursor: pointer;
    border-radius: 4px;
    font-size: 27px;
    transition: all 0.2s ease-out;
  }
  
  
.Trainer_name_head{
    font-size:27px;
    font-weight: 600;
    font-family: Inter;
    margin-bottom: 20px;

}
.Trainer_detail_comp{
    width: 70%;
display: flex;
align-items: center;
justify-content: center;

}
.Trainer_detail_cad{
    width: 500px;
    border-radius: 20px;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.05);
}
.Trainer_detail_cad_head{
    text-align: center;
    font-size: 28px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: Inter;
    font-weight: 650;
}
.Trainer_detail_cad_detail{
    font-size: 25px;
    font-family: Inter;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 25px;
    margin-top: 10px;
}
.Trainer_detail_cad__name{
    font-size: 25px;
    font-family: Inter;
    font-weight: 600;
    opacity: 0.5;
}
.Trainer_detail_cad__url{
    font-size: 23px;
    font-family: Inter;
    font-weight: 600;
    float: right;
    margin: 10px 10px;
    color: rgba(37,214,225,1);
}