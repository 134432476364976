.Footer{
    height: 600px;
   
background-color: black;
display: grid;
grid-template-columns: 30rem 1fr 1fr 1fr 1fr 30rem;
}
.fotimg{
    margin-right: 50px;
    display: grid;
    grid-column: 2/3;
    justify-content: space-around;
    margin-top: 90px;
    margin-bottom: 70px;
    align-items: center;
}
.fotsup{
    display: grid;
    grid-column: 4/5;
    justify-content: space-around;
    
    margin-bottom: 90px;
    align-items: center;
}
.fotcon{
    display: grid;
    grid-column: 5/6;
    justify-content: space-around;
  
    margin-bottom: 90px;
    align-items: center;
}
.fotcomp{
    display: grid;
    grid-column: 3/4;
    justify-content: space-around;
  
    margin-bottom: 90px;
    align-items: center;
}

@media (max-width: 520px){
    .Footer{
        height: 140rem;
        background-color: black;
        display: grid;
        grid-template-columns:1fr;
        }
        .fotimg{
            margin-left: 35px;
            display: grid;
            grid-column: 1/2;
            justify-content: space-around;
            margin-top: 70px;
            margin-bottom: 20px;
            align-items: center;
        }
        .fotsup{
            display: grid;
            grid-column: 1/2;
            justify-content: space-around;
            margin-top: 20px;
            margin-bottom: 20px;
            align-items: center;
        }
        .fotcon{
            margin-left: 30px;
            display: grid;
            grid-column: 1/2;
            justify-content: space-around;
            margin-top: 20px;
            margin-bottom: 20px;
            align-items: center;
        }
        .fotcomp{
            display: grid;
            grid-column: 1/2;
            justify-content: space-around;
            margin-top: 20px;
            margin-bottom: 20px;
            align-items: center;
        }
}
