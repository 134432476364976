.All_training_container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.Main_container{
    margin-top: 50px;
    width: 80%;
    margin-bottom: 50px;
}
.Alltraining_head{
    font-family: Whyte Inktrap;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.25;
  
    max-width: 36em;
}
.Alltraining_text{
    color: #6a6f73;
    font-size: 18px;
    font-weight: 600;
 }
 .All_span{
    color: #1A53DD;
    font-weight: 500;
 }
 .user_collection{
    padding-top: 10px;
    padding-left: 5px;
    font-weight: 600;
 }