.course_link_btn{
    font-size: 15px;
    font-family: Inter;
    color:#1A53DD;
    text-decoration: none;
    cursor: pointer;

}
/* other */


.dash_usercom{
    border: 1px solid white;
   width:300px;
    /* height: 20rem; */
    margin-left: 20px;
    border-radius: 20px;
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.06); 
    margin-top: 50px;
}
.lecture_head{
    font-size: 18px;
    font-family: Inter;
    margin: 10px 20px;
}

.Lecture_link{
    font-size: 20px;
    font-family: Inter;
   border: 1px solid rgba(37,214,225,1);
    color:  rgba(37,214,225,1);
   margin: 10px 60px;
    text-align: center;
    margin-top: 20px;
    border-radius: 10px;
    height: 32px;
}
.Lecture_link1{
    font-size: 20px;
    font-family: Inter;
   border: 1px solid red;
    color:  red;
   margin: 10px 60px;
    text-align: center;
    border-radius: 10px;
    height: 32px;


}


.Lecture_linkk{
    font-size: 20px;
    font-family: Inter;
   border: 1px solid rgba(37,214,225,1);
    color:  rgba(37,214,225,1);
   margin: 10px 50px;
    text-align: center;
    margin-top: 20px;
    border-radius: 10px;
}
.Lecture_link1k{
    font-size: 20px;
    font-family: Inter;
   border: 1px solid red;
    color:  red;
   margin: 10px 50px;
    text-align: center;
    border-radius: 10px;

}
.Lecture_content{
    font-size: 16px;
    font-family: Inter;
}
