:root {
  --primary: rgb(31, 227, 241);
 
}

.btnn {
  width: 150px;
  padding: 8px 20px;
  height: 50px;
  border-radius: 50px;
  outline: none;
  margin-left: 50px;
  border: none;
  font-size: 18px;

  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btnn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: rgb(15, 233, 248);
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
@media screen and (max-width: 960px){
  .btnn{
    display:none !important;
  }
}